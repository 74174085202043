import React from 'react';

const ConfirmButtons = ({ onConfirm, onCancel, confirmText = 'Confirm', cancelText = 'Cancel', disableConfirm=false }) => {
    const [actionPending, setActionPending] = React.useState(false);
    const [actionPendingCancel, setActionPendingCancel] = React.useState(false);
    return (
        <div className="flex-center-center">
            <button
                disabled={actionPendingCancel}
                onClick={async (e) => {
                    setActionPendingCancel(true);
                    await onCancel(e);
                    setActionPendingCancel(false);
                }}
                className="button-secondary mr-8p"
            >
                {window.translate(cancelText)}
            </button>
            <button
                disabled={actionPending | disableConfirm ? true : false}
                onClick={async (e) => {
                    setActionPending(true);
                    await onConfirm(e);
                    setActionPending(false);
                }}
                className="button-primary mr-8p"
            >
                {actionPending ? (
                    <React.Fragment>
                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>{' '}
                        {window.translate('Processing...')}
                    </React.Fragment>
                ) : (
                    window.translate(confirmText)
                )}
            </button>
        </div>
    );
};

export default ConfirmButtons;
