import { InvoiceConclusionDetailsModel } from "@common/modelDefinition";


export const getGroupedInvoiceConclusionsByTypeOfPlus = (invoiceConclusionDetails: InvoiceConclusionDetailsModel[]) => {
    const groupedInvoiceConclusionDetails = invoiceConclusionDetails.reduce((result: any, invoiceConclusionDetail: InvoiceConclusionDetailsModel) => {
        const type = invoiceConclusionDetail.type;
        if (type) {
            if (!result[type]) {
                result[type] = [];
            }
            result[type].push(invoiceConclusionDetail);
        }
        return result;
    }, {});
    return groupedInvoiceConclusionDetails;
};

export const getGroupedInvoiceConclusionsBySubTypeOfPlus = (invoiceConclusionDetails: InvoiceConclusionDetailsModel[]) => {
    const groupedInvoiceConclusionDetails = invoiceConclusionDetails.reduce((result: any, invoiceConclusionDetail: InvoiceConclusionDetailsModel) => {
        const subtype = invoiceConclusionDetail.subtype ?  invoiceConclusionDetail.subtype : 'null'


        if (subtype) {

            if (!result[subtype]) {
                result[subtype] = [];
            }

            result[subtype].push(invoiceConclusionDetail);
        }
        return result;
    }, {});

    return groupedInvoiceConclusionDetails;
};

