import React from 'react';
import _ from 'lodash';
import { attributeComponents } from './attributesIcons';

function RoomAttributes(props) {
    let roomAttributes = props?.room?.roomAttributes ? JSON.parse(props.room.roomAttributes) : [];
    let attributes = _.filter(props?.attributes, (att) => {
        if (_.includes(roomAttributes, att.id)) {
            return att;
        }
    });
    let attributesList = [];

    if (!_.isEmpty(attributes)) {
        attributesList = _.map(attributes, (att, key) => {
            const AttrComp = attributeComponents[att.name];
            return (
                <span key={key} className="p-1" style={{ width: '25px', height: '25px', marginBottom: '3px' }}>
                    {AttrComp ? <AttrComp /> : null}
                </span>
            );
        });
    } else {
        attributesList = null; //<span className="mr-2 p-1">No assigned attributes</span>
    }

    if (attributesList === null) {
        return null;
    }

    return <div className="">{attributesList}</div>;
}

export default RoomAttributes;

//required props
//content nije required
//showOnlyCloseButton nije required
